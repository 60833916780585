<template>
  <i-modal
    :title="$t(edit ? 'editing' : 'add', { value: $tc('templates_email', 1) })"
    :value="value"
    :load="loading"
    @submit="submit"
    @input="$emit('input', $event)"
  >
    <v-row>
      <v-col cols="12">
        <v-skeleton-loader v-if="loading" type="text" tile></v-skeleton-loader>
        <v-switch v-else v-model="emailTemplate.active" :label="$t('active')" />
      </v-col>

      <v-col cols="12">
        <v-skeleton-loader v-if="loading" type="text" tile></v-skeleton-loader>
        <ValidationProvider
          vid="orderstatus"
          :name="$tc('orderstatus', 1)"
          rules="required"
          v-slot="{ errors }"
        >
          <v-select
            outlined
            v-model="emailTemplate.mainstatus"
            :items="mainStatus"
            item-text="text"
            item-value="value"
            :label="$t('orderstatus')"
            :error-messages="errors[0]"
            @change="validateMainStatus"
          />
        </ValidationProvider>
      </v-col>

      <v-col v-if="emailTemplate.mainstatus === 'in_process'" cols="12" md="6">
        <v-skeleton-loader v-if="loading" type="text" tile></v-skeleton-loader>
        <ValidationProvider
          vid="emailTemplate"
          :name="$t('template')"
          rules="required"
          v-slot="{ errors }"
        >
          <v-select
            outlined
            v-model="emailTemplate.template"
            :items="templates"
            item-text="name"
            item-value="pk"
            :label="$t('template')"
            :error-messages="errors[0]"
          />
        </ValidationProvider>
      </v-col>

      <v-col v-if="emailTemplate.template > 0" cols="12" md="6">
        <v-skeleton-loader v-if="loading" type="text" tile></v-skeleton-loader>
        <ValidationProvider
          vid="suborderstatus"
          :name="$tc('suborderstatus', 1)"
          rules="required"
          v-slot="{ errors }"
        >
          <v-select
            outlined
            v-model="emailTemplate.suborderstatus"
            :items="suborderStatus"
            item-text="text"
            :label="$t('suborderstatus')"
            :error-messages="errors[0]"
            @change="
              getOrderStatus(
                emailTemplate.template,
                emailTemplate.suborderstatus
              )
            "
          />
        </ValidationProvider>
      </v-col>

      <v-col v-if="emailTemplate.suborderstatus" cols="12" md="6">
        <v-skeleton-loader v-if="loading" type="text" tile></v-skeleton-loader>
        <ValidationProvider
          vid="subordersubstatus"
          :name="$tc('subordersubstatus', 1)"
          rules="required"
          v-slot="{ errors }"
        >
          <v-select
            outlined
            v-model="emailTemplate.status"
            :items="stages"
            item-text="name"
            item-value="pk"
            :label="$t('subordersubstatus')"
            :error-messages="errors[0]"
          />
        </ValidationProvider>
      </v-col>

      <v-col
        v-show="emailTemplate.mainstatus"
        cols="12"
        md="6"
        :lg="emailTemplate.mainstatus === 'in_process' ? 6 : 12"
      >
        <v-skeleton-loader v-if="loading" type="text" tile></v-skeleton-loader>
        <ValidationProvider
          vid="name"
          :name="$tc('name', 1)"
          rules="required|max:32"
          v-slot="{ errors }"
        >
          <v-text-field
            outlined
            v-model="emailTemplate.name"
            :error-messages="errors[0]"
            color="secondary"
            :label="$tc('name', 1)"
          />
        </ValidationProvider>
      </v-col>

      <v-col cols="12">
        <ValidationProvider
          vid="body"
          :name="$tc('body', 1)"
          rules=""
          v-slot="{ errors }"
        >
          <v-skeleton-loader v-if="loading" type="article" tile />
          <label v-if="!loading" for="body">
            {{ $t('body') }}
          </label>
          <vue-editor
            v-if="!loading"
            id="body"
            v-model="emailTemplate.body"
            :editorToolbar="customToolbar"
          />
          <span class="areaErrors" v-if="errors[0]">{{ errors[0] }}</span>
        </ValidationProvider>
      </v-col>

      <v-col cols="12">
        <v-skeleton-loader v-if="loading" type="text" tile></v-skeleton-loader>
        <v-textarea
          outlined
          name="sms"
          :rows="3"
          v-model="emailTemplate.sms"
          color="secondary"
          :label="$tc('sms', 1)"
        />
      </v-col>
    </v-row>
  </i-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import { VueEditor } from 'vue2-editor'
export default {
  components: {
    VueEditor
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    toEdit: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      templates: [],
      loading: false,
      emailTemplate: {
        pk: '',
        active: true,
        template: '',
        mainstatus: '',
        suborderstatus: '',
        status: '',
        name: '',
        body: '',
        sms: '',
        company: ''
      },
      edit: false,
      customToolbar: [
        [{ header: [] }],
        [
          'bold',
          'italic',
          'underline',
          'strike',
          'blockquote',
          'code',
          'code-block'
        ],
        [
          { align: '' },
          { align: 'center' },
          { align: 'right' },
          { align: 'justify' }
        ],
        [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
        [{ script: 'sub' }, { script: 'super' }],
        [{ indent: '-1' }, { indent: '+1' }],
        [{ color: [] }, { background: [] }]
      ],
      mainStatus: [
        {
          value: 'sended_quote',
          text: this.$t('sended_quote')
        },
        {
          value: 'in_process',
          text: this.$t('in_process')
        }
      ],
      suborderStatus: [
        {
          value: 'design',
          text: this.$tc('design', 1)
        },
        {
          value: 'production',
          text: this.$t('production')
        }
      ],
      stages: []
    }
  },
  computed: {
    ...mapGetters({
      company: 'company/getCompanyData'
    })
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (val) {
          this.edit = this.toEdit !== null
          this.emailTemplate = this.edit
            ? { ...this.toEdit }
            : {
                pk: '',
                active: true,
                template: '',
                mainstatus: '',
                suborderstatus: '',
                status: '',
                name: '',
                body: '',
                sms: '',
                company: this.company.pk
              }
          if (this.edit && this.emailTemplate.template > 0) {
            this.getOrderStatus(
              this.emailTemplate.template,
              this.emailTemplate.suborderstatus
            )
          }
        }
      }
    }
  },
  methods: {
    async getTemplates() {
      const { data } = await this.$api.product.template.list({
        opt: { params: { company: this.company.pk } }
      })
      this.templates = data
    },
    async getOrderStatus(template, suborderstatus) {
      const { data } = await this.$api.sale.status.list({
        opt: { params: { template } }
      })
      this.stages = data.reduce((acc, item) => {
        if (item.substatusVals.length > 0) {
          const substatusVals = item.substatusVals.map((substatus) => ({
            ...substatus,
            name: this.$t(substatus.name)
          }))
          return acc.concat(item.name === 'design' ? substatusVals : [])
        }
        return acc
      }, [])
    },
    validateMainStatus() {
      if (this.emailTemplate.mainstatus !== 'in_process') {
        this.emailTemplate.suborderstatus = ''
      }
    },
    async submit() {
      if (!this.loading) {
        try {
          this.loading = true
          const emailTemplate = !this.edit
            ? await this.$api.email.template.create({
                form: this.emailTemplate
              })
            : await this.$api.email.template.edit({
                pk: this.emailTemplate.pk,
                form: this.emailTemplate
              })
          this.$emit('done', emailTemplate.data)
          this.$emit('input', false)
          this.$toast.success(
            `${this.$tc('template', 1)} ${this.$tc(
              this.edit ? 'edited' : 'created',
              1
            )}`
          )
        } finally {
          this.loading = false
        }
      }
    }
  },
  mounted() {
    this.getTemplates()
  }
}
</script>
