<route>
{
  "meta": {
    "permission": [
      "emails.view_template"
    ]
  }
}
</route>

<template>
  <v-container fluid>
    <dialemail v-model="dialog" :to-edit="toEdit" @done="reload = true" />
    <settingTemplate
      v-model="dialog2"
      :to-edit="toEdit"
      @done="reload = true"
      ref="settingTemplate"
    />
    <previewChangeStatus ref="previewChangeStatus" />
    <i-table
      :title="$tc('templates_email', 2)"
      :headers="headers"
      app="emails.template"
      api="email.template"
      :reload.sync="reload"
      @click:create="open()"
      @click:edit="open($event)"
      :opt="{ company: company.pk }"
    >
      <template v-slot:extraToolbar v-if="templateBtn.edit">
        <v-divider class="mx-4" inset vertical></v-divider>
        <i-btn
          text
          :small="$vuetify.breakpoint.smAndDown"
          icon="fa-cogs"
          :title="$t('settings')"
          color="text-dark"
          @click="openSetting()"
        >
        </i-btn>
      </template>
      <template v-slot:item.mainstatus="{ item }">
        <span>
          {{
            item.mainstatus === 'sended_quote'
              ? $t('sended_quote')
              : item.mainstatus === 'in_process'
              ? $t('in_process')
              : item.mainstatus
          }}
        </span>
      </template>
      <template v-slot:item.suborderstatus="{ item }">
        <span>
          {{
            item.mainstatus === 'sended_quote'
              ? ''
              : item.suborderstatus === 'design'
              ? $tc('design', 1)
              : item.suborderstatus === 'production'
              ? $t('production')
              : item.suborderstatus
          }}
        </span>
      </template>
      <template v-slot:item.nameStatus="{ item }">
        <span>
          {{
            item.nameStatus === 'sended_quote'
              ? ''
              : item.nameStatus === 'in_wait'
              ? $t('in_wait')
              : item.nameStatus === 'rejected'
              ? $t('rejected')
              : item.nameStatus === 'in_design'
              ? $t('in_design')
              : item.nameStatus === 'in_production'
              ? $t('in_production')
              : item.nameStatus === 'finished'
              ? $t('finished')
              : item.nameStatus
          }}
        </span>
      </template>
      <template v-slot:item.active="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              outlined
              :color="item.active ? 'success' : 'error'"
              v-on="on"
            >
              <v-icon
                v-text="item.active ? 'fas fa-check' : 'fas fa-minus'"
              ></v-icon>
            </v-btn>
          </template>
          <span>{{ $t('active') }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.body="{ item }">
        <span v-html="item.body"></span>
      </template>
    </i-table>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import dialemail from '../../components/templatesEmail/modal.vue'
import settingTemplate from '../../components/email/settingTemplate.vue'
import previewChangeStatus from '../../components/email/changeStatus.vue'

export default {
  components: {
    dialemail,
    settingTemplate,
    previewChangeStatus
  },
  data() {
    return {
      loading: false,
      stages: [],
      reload: false,
      dialog: false,
      dialog2: false,
      toEdit: null
    }
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('name'),
          value: 'name'
        },
        {
          text: this.$t('active'),
          value: 'active'
        },
        {
          text: this.templateBtn.view ? this.$t('production_process') : '',
          value: this.templateBtn.view ? 'templateName' : ''
        },
        {
          text: this.$t('main_status'),
          value: 'mainstatus'
        },
        {
          text: this.$t('suborderstatus'),
          value: 'suborderstatus'
        },
        {
          text: this.$t('subordersubstatus'),
          value: 'nameStatus'
        },
        {
          text:
            this.templateBtn.delete || this.templateBtn.edit
              ? this.$t('action')
              : '',
          value:
            this.templateBtn.delete || this.templateBtn.edit ? 'actions' : ''
        }
      ]
    },
    ...mapGetters({
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin',
      company: 'company/getCompanyData'
    }),
    /**
     * templateBtn
     * Computado para permisos para eliminar, editar o ver
     * plantillas de proceso de producción
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    templateBtn() {
      return {
        delete: this.getPermissions([`emails.delete_template`]) || this.isAdmin,
        edit: this.getPermissions([`emails.change_template`]) || this.isAdmin,
        view:
          this.getPermissions([`product.view_producttemplate`]) || this.isAdmin
      }
    }
  },
  methods: {
    open(item) {
      this.toEdit = item === undefined ? null : item
      this.dialog = true
    },
    openSetting() {
      this.dialog2 = true
      this.$refs.settingTemplate.getSettingTemplate()
    }
  }
}
</script>
